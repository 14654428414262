import { TransFunction } from 'app';
import { AppContext } from 'app/AppContext';
import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { History } from 'history';
import { RouteViewProps } from 'lib';
import { withAcl } from 'lib/decorators/acl';
import {
  Badge,
  BreadcrumbItem,
  Button,
  Column,
  DataTable,
  Page,
  Portlet,
} from 'lib/metronic/components';
import {
  AclObjectList,
  DeliveryCheckTemplateMatchType,
  Identity,
  VehicleDeliveryCheckTemplate,
  VehicleDeliveryCheckTemplateListFilter,
} from 'model';
import { DeliveryCheckTemplateConf } from 'model/viewmodel/DeliveryCheckTemplateConf';
import moment from 'moment';
import { ChangeEvent, Component, CSSProperties, MouseEvent } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { vehicleDeliveryCheckTemplateService } from 'services';
import {
  authenticate,
  CommonEntityListProps,
  ConfirmDeleteModal,
  getString,
  ListToolbar,
  ToolbarItemsBuilder,
} from 'shared/components';
import { loadAsyncList } from 'utils';
import { Colors } from 'utils/Colors';
import { deliveryCheckTemplateActions } from '../duck/actions';
import { duplicateDeliveryCheckTemplate } from '../duck/actions/delivery-check-templates';
import { DeliveryCheckTemplates } from '../duck/states';
import { BarriersSettings } from './BarriersSettings';
import { DeliveryCheckTemplateEditor } from './editor';

const pageIcon =
  require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg').default;

type TemplateListColumn = Column<VehicleDeliveryCheckTemplate>;

interface Props extends CommonEntityListProps, LocalizeContextProps {
  templates: DeliveryCheckTemplates;
  history: History;
  activeStorId?: number | null;
  identity: Identity;
}

export type DeliveryCheckTemplateManagerProps = Props;

function mapStateToProps(
  state: AppState,
  ownProps: RouteViewProps,
): Partial<Props> {
  return {
    history: ownProps.history,
    templates: state.inspection.deliveryCheckTemplates,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    activeStorId: state.activeStoreId,
    identity: state.identity!,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

type State = {
  activeTemplateForBarriersSettings: VehicleDeliveryCheckTemplate | undefined;
};

@withAcl()
class DeliveryCheckTemplateManagerImpl extends Component<Props, State> {
  state = {
    activeTemplateForBarriersSettings: undefined,
  };

  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.delivery_check_templates" /> },
  ];

  private columns: TemplateListColumn[] | undefined;

  componentDidMount() {
    this.loadTemplates();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.activeStorId !== prevProps.activeStorId) {
      const { dispatch } = this.props;
      dispatch(deliveryCheckTemplateActions.clearSelection());
    }
  }

  render() {
    const { trans, templates } = this.props;
    return (
      <Page
        title={trans('delivery_check_template.manager.title')}
        fullAccessRight={AclObjectList.VehicleDeliveryCheckTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleDeliveryCheckTemplateReadonlyAccess
        }
        error={templates.error}
        className="delivery-check-template-manager"
      >
        <Page.Header>
          <Page.Header.Main>
            <Page.Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>{this.renderToolbar()}</Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet mobile>
            <Portlet.Header
              size="large"
              title={trans('delivery_check_template.manager.title')}
              icon={pageIcon}
              iconColor="brand"
              onRefresh={this.onRefresh}
            />
            <Portlet.Body>
              {this.renderDataList()}
              {this.renderSidebar()}
              {this.renderConfirmDeleteModal()}
              {this.renderBarriersSettingsSidebar()}
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  renderToolbar() {
    const { dispatch, templates } = this.props;
    const builder =
      new ToolbarItemsBuilder<VehicleDeliveryCheckTemplateListFilter>();
    builder
      .button({
        placement: 'right',
        buttonType: 'refresh',
        onClick: this.onRefresh,
      })
      .button({
        placement: 'right',
        buttonType: 'add',
        onClick: () => {
          dispatch(
            deliveryCheckTemplateActions.itemBeingCreated({
              orgId: this.props.identity.orgId,
            }),
          );
        },
      })
      .button({
        placement: 'right',
        iconOnly: true,
        size: 'small',
        cls: 'btn-import-template',
        file: true,
        accepts: ['application/json'],
        onFileChange: this.onImportTemplates,
        text: (
          <i
            className="la la-upload"
            data-toggle="tooltip"
            title={getString(
              'inspection_template.toolbar.button.import_template',
            )}
          />
        ),
      });
    return (
      <ListToolbar<VehicleDeliveryCheckTemplateListFilter>
        filter={templates.filter || {}}
        items={builder.build()}
        onFilterChange={this.onListFilterChange}
      />
    );
  }

  onListFilterChange = () => {
    /* noop */
  };

  renderSidebar() {
    const { dispatch, templates } = this.props;
    return (
      <DeliveryCheckTemplateEditor
        entities={templates}
        actions={deliveryCheckTemplateActions}
        dispatch={dispatch}
        extra={{ props: this.props }}
      />
    );
  }

  renderConfirmDeleteModal() {
    const { templates } = this.props;
    return (
      <ConfirmDeleteModal
        localeSegment={'delivery_check_template'}
        isOpen={Boolean(templates.itemsBeingDeleted?.[0])}
        isDeleting={templates.isDeleting}
        error={templates.lastDeleteError}
        onConfirm={this.onConfirmDelete}
        onCancel={this.onCancelDelete}
      />
    );
  }

  renderDataList() {
    return (
      <AppContext.Consumer>
        {({ identity }) => {
          const { templates, activeStorId } = this.props;
          let data = templates.result;
          if (data && activeStorId) {
            data = data.filter(x => x.storeId === activeStorId);
          }

          const columns = this.buildColumns(identity);
          return (
            <DataTable<VehicleDeliveryCheckTemplate, number>
              columns={columns}
              idProp="id"
              selModel="none"
              data={data}
              isLoading={templates.isLoading}
              minHeight={400}
              selection={templates.selection}
            />
          );
        }}
      </AppContext.Consumer>
    );
  }

  renderBarriersSettingsSidebar() {
    return (
      <BarriersSettings
        template={this.state.activeTemplateForBarriersSettings}
        onClose={this.onBarriersSettingsClose}
      />
    );
  }

  onRefresh = () => {
    this.loadTemplates(true);
  };

  onBarriersSettingsClose = () => {
    this.setState({ activeTemplateForBarriersSettings: undefined });
  };

  onAdd = () => {
    const { dispatch } = this.props;
    dispatch(
      deliveryCheckTemplateActions.itemBeingCreated({
        orgId: this.props.identity.orgId,
      }),
    );
  };

  onConfirmDelete = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateActions.commitItemsBeingDeleted());
  };

  onCancelDelete = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateActions.cancelItemsBeingDeleted());
  };

  onImportTemplates = async (e: ChangeEvent<HTMLInputElement>) => {
    const accepts = ['application/json'];
    const file = this.acceptFile(e, accepts, 'invalid_file');
    if (!file) return;
    const { dispatch } = this.props;
    dispatch(
      showAppLoading({
        message: getString('delivery_check_template.manager.importing'),
        status: 'loading',
      }),
    );
    try {
      await vehicleDeliveryCheckTemplateService.importTemplates(
        this.props.activeStorId,
        file,
      );
      dispatch(deliveryCheckTemplateActions.invalidate(true));
      dispatch(
        showAppLoading({
          message: getString('delivery_check_template.manager.import_success'),
          status: 'success',
          timeout: 2000,
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(hideAppLoading());
      dispatch(
        showAppModal(
          getString('delivery_check_template.modal.import_error.title'),
          getString('delivery_check_template.modal.import_error.msg'),
        ),
      );
    }
  };

  acceptFile(
    e: ChangeEvent<HTMLInputElement>,
    accepts: string[],
    error: string,
  ): File | false {
    if (!e.target.files?.length) return false;
    const file = e.target.files[0];
    if (!accepts.includes(file.type)) {
      alert(getString(`delivery_check_template.manager.${error}`));
      return false;
    }
    return file;
  }

  loadTemplates(force?: boolean) {
    const { dispatch, templates } = this.props;
    loadAsyncList(
      templates,
      () => dispatch(deliveryCheckTemplateActions.fetch()),
      force,
    );
  }

  async exportTemplates(templates: VehicleDeliveryCheckTemplate[]) {
    const ids = templates.map(x => x.id);
    const url = vehicleDeliveryCheckTemplateService.getExportTemplatesUrl(ids);
    console.log('download url is: %s', url);
    const link = document.createElement('a');
    const time = moment().format('YYYYMMDD');
    link.download =
      templates.length === 1
        ? `${templates[0].name}-exported-${time}.json`
        : `exported-templates-${time}.json`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  buildColumns(identity: Identity): TemplateListColumn[] {
    if (this.columns) return this.columns;
    const { trans } = this.props;
    const columns: TemplateListColumn[] = [
      {
        prop: 'source',
        text: trans('delivery_check_template.col.source'),
        width: 50,
        align: 'center',
        render: template => {
          const source = template.agentId
            ? 'agent'
            : template.storeId
              ? 'store'
              : template.orgId
                ? 'org'
                : 'system';
          return <Translate id={`delivery_check_template.source.${source}`} />;
        },
      },
      {
        prop: 'name',
        text: trans('delivery_check_template.col.name'),
        width: 300,
        render(item) {
          return (
            <div>
              <p style={{ marginBottom: '0.25rem' }}>
                <strong>{item.name}</strong>
              </p>
              <div>
                {item.withInspectionResults ===
                DeliveryCheckTemplateMatchType.Yes ? (
                  <MatchTypeLabel
                    label="with_inspection_results"
                    style={{
                      borderColor: Colors.teal,
                      color: Colors.teal,
                    }}
                  />
                ) : item.withInspectionResults ===
                  DeliveryCheckTemplateMatchType.No ? (
                  <MatchTypeLabel
                    label="without_inspection_results"
                    style={{
                      borderColor: Colors.danger,
                      color: Colors.danger,
                    }}
                  />
                ) : null}
                {item.withConstructionJobs ===
                DeliveryCheckTemplateMatchType.Yes ? (
                  <MatchTypeLabel
                    label="with_construction_jobs"
                    style={{
                      borderColor: Colors.cyan,
                      color: Colors.cyan,
                    }}
                  />
                ) : item.withConstructionJobs ===
                  DeliveryCheckTemplateMatchType.No ? (
                  <MatchTypeLabel
                    label="without_construction_jobs"
                    style={{
                      borderColor: Colors.orange,
                      color: Colors.orange,
                    }}
                  />
                ) : null}
                {item.withOrderType ? (
                  <MatchTypeLabel
                    label="with_order_type"
                    suffix={item.withOrderType}
                    style={{
                      borderColor: Colors.purple,
                      color: Colors.purple,
                    }}
                  />
                ) : null}
              </div>
            </div>
          );
        },
      },
      {
        prop: 'isSystemDefault',
        text: trans('delivery_check_template.col.is_system_default'),
        width: 80,
        align: 'center',
        render: ({ isSystemDefault }) => {
          return isSystemDefault ? (
            <Badge color="success">
              <Translate id="delivery_check_template.label.default" />
            </Badge>
          ) : (
            '/'
          );
        },
      },
      {
        prop: 'description',
        text: trans('delivery_check_template.col.description'),
        width: 150,
      },
      {
        prop: 'enabled',
        text: trans('delivery_check_template.col.enabled'),
        width: 80,
        align: 'center',
        render: ({ disabled }) => {
          return (
            <i
              className={classNames('la la-check-circle', {
                'm--font-success': !disabled,
                'm--font-metal': disabled,
              })}
            />
          );
        },
      },
      {
        prop: 'conf',
        text: trans('delivery_check_template.col.conf'),
        width: 120,
        render: site => {
          const conf = site.conf
            ? (JSON.parse(site.conf) as DeliveryCheckTemplateConf)
            : null;
          if (!conf?.items.length) {
            return (
              <span
                style={{
                  color: Colors.warning,
                  border: '1px solid ' + Colors.warning,
                  padding: '0 0.25rem',
                  borderRadius: 3,
                }}
              >
                <Translate id="delivery_check_template.label.no_conf" />
              </span>
            );
          }
          return (
            <Translate
              id="delivery_check_template.label.conf"
              data={{
                itemCount: conf.items.length,
              }}
            />
          );
        },
      },
    ];
    this.addActionButtons(identity, columns);
    this.columns = columns;
    return columns;
  }

  addActionButtons(identity: Identity, columns: TemplateListColumn[]) {
    const fullAccessRight = AclObjectList.VehicleInspectionSiteFullAccess;
    if (!identity.hasAccessRights(fullAccessRight)) {
      columns.push({
        prop: 'actions',
        text: <Translate id="col.actions" />,
        align: 'center',
        width: 135,
        render: (template: VehicleDeliveryCheckTemplate) => {
          const onViewDetail = (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            const { history } = this.props;
            setTimeout(() => {
              history.push(
                '/inspection/delivery-check-templates/detail?id=' + template.id,
              );
            }, 0);
          };
          return (
            <>
              <Button
                size="small"
                clean
                iconOnly
                data-toggle="tooltip"
                data-container=".delivery-check-template-manager"
                title={getString('delivery_check_template.tooltip.view_detail')}
                onClick={onViewDetail}
              >
                <i className="la la-eye" />
              </Button>
            </>
          );
        },
      });
      return;
    }
    columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 215,
      render: (template: VehicleDeliveryCheckTemplate) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(deliveryCheckTemplateActions.itemBeingUpdated(template));
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(deliveryCheckTemplateActions.itemsBeingDeleted([template]));
        };
        const onConfig = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { history } = this.props;
          setTimeout(() => {
            history.push(
              '/inspection/delivery-check-templates/detail?id=' + template.id,
            );
          }, 0);
        };
        const onDuplicate = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(duplicateDeliveryCheckTemplate(template));
        };
        const onExport = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          void this.exportTemplates([template]);
        };
        const onBarriersSettings = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.setState({
            activeTemplateForBarriersSettings: template,
          });
        };
        const hasPermission = Boolean(
          template.orgId &&
            ((template.storeId &&
              identity.visibleStoreSet.has(template.storeId)) ||
              (!template.storeId && identity.userInfo.isOrgRootUser)),
        );

        return (
          <>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.edit')}
              disabled={!hasPermission}
              onClick={onEdit}
            >
              <i className="la la-edit" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.config')}
              disabled={!hasPermission}
              onClick={onConfig}
            >
              <i className="la la-cog" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.duplicate')}
              onClick={onDuplicate}
            >
              <i className="la la-copy" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              onClick={onBarriersSettings}
              data-toggle={hasPermission ? 'tooltip' : ''}
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.barriers')}
              disabled={template.storeId != null}
            >
              <i className="flaticon-eye" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.export')}
              onClick={onExport}
              disabled={!hasPermission}
            >
              <i className="la la-download" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.delete')}
              onClick={onDelete}
              disabled={!hasPermission}
            >
              <i className="la la-trash" />
            </Button>
          </>
        );
      },
    });
  }
}

export const DeliveryCheckTemplateManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, DeliveryCheckTemplateManagerImpl>(
    withLocalize<Props>(DeliveryCheckTemplateManagerImpl) as any,
  ),
);

const MatchTypeLabel = ({
  label,
  suffix,
  style,
}: {
  label: string;
  suffix?: string;
  style?: CSSProperties;
}) => {
  return (
    <span
      style={{
        borderRadius: '3px',
        fontSize: '0.9rem',
        border: '1px solid',
        padding: '0 0.25rem',
        marginTop: '0.25rem',
        marginRight: '0.25rem',
        whiteSpace: 'nowrap',
        ...style,
      }}
    >
      <Translate id={`delivery_check_template.match_label.${label}`} />
      {suffix ? `: ${suffix}` : null}
    </span>
  );
};
