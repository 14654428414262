import { useAppContext } from 'app/AppContext';
import { DisplayInOrderSummaryLabel } from 'app/settings/DisplayInOrderSummaryLabel';
import { PropertyEditor } from 'app/settings/PropertyEditor';
import { Badge, Button, Column, DataTable } from 'lib/metronic/components';
import { AclObjectList, ExtendedPropertyDef } from 'model';
import { memo, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { Checkmark, getString } from 'shared/components';
import { isNotNull } from 'utils';

type ColumnType = Column<ExtendedPropertyDef>;

export type ActiveExtendedProperty = {
  type: 'new' | 'edit';
  property: ExtendedPropertyDef;
};

export const ExtendedProperties = memo(
  ({
    properties,
    activeProperty,
    onEditorClose,
    onEdit,
    onRemove,
    onSave,
    onMove,
  }: {
    properties: ExtendedPropertyDef[];
    activeProperty?: ActiveExtendedProperty;
    onEdit?: (property: ExtendedPropertyDef) => void;
    onSave?: (property: ExtendedPropertyDef) => void;
    onRemove?: (property: ExtendedPropertyDef) => void;
    onMove?: (property: ExtendedPropertyDef, dir: 'up' | 'down') => void;
    onEditorClose?: () => void;
  }) => {
    const { identity } = useAppContext();

    const groupNames = useMemo(
      () => [
        ...new Set(
          properties
            .map(x => x.group)
            .filter(x => x)
            .filter(isNotNull),
        ),
      ],
      [properties],
    );

    const canEdit = identity.hasAccessRights(
      AclObjectList.StoreConfigurationFullAccess,
    );

    const columns = useMemo<ColumnType[]>(() => {
      const list: ColumnType[] = [
        {
          prop: 'id',
          text: <Translate id="store.config.extended_property.col.id" />,
          width: 50,
        },
        {
          prop: 'type',
          text: <Translate id="store.config.extended_property.col.type" />,
          width: 100,
          align: 'center',
          render: prop => (
            <Badge
              color={
                prop.type === 'select'
                  ? prop.multi
                    ? 'brand'
                    : 'warning'
                  : prop.type === 'text'
                    ? 'dark'
                    : 'success'
              }
            >
              {prop.type === 'select' ? (
                <Translate
                  id={`store.config.prop.type.${
                    prop.multi ? 'multi_select' : 'single_select'
                  }`}
                />
              ) : (
                <Translate id={`store.config.prop.type.${prop.type}`} />
              )}
            </Badge>
          ),
        },
        {
          prop: 'group',
          text: <Translate id="store.config.extended_property.col.group" />,
          width: 120,
          render: ({ group }) => group || '-',
        },
        {
          prop: 'name',
          text: <Translate id="store.config.extended_property.col.label" />,
          width: 100,
        },
        {
          prop: 'visibility',
          text: (
            <Translate id="store.config.extended_property.col.visibility" />
          ),
          width: 80,
          render: ({ visibility }) => (
            <Badge color={visibility === 'private' ? 'danger' : 'success'}>
              <Translate
                id={`store.config.prop.visibility.${visibility ?? 'public'}`}
              />
            </Badge>
          ),
        },
        {
          prop: 'required',
          width: 60,
          align: 'center',
          text: <Translate id="store.config.extended_property.col.required" />,
          render: ({ required }) => <Checkmark value={required} />,
        },
        {
          prop: 'autoFill',
          width: 60,
          align: 'center',
          text: <Translate id="store.config.extended_property.col.auto_fill" />,
          render: ({ autoFill }) => <Checkmark value={autoFill} />,
        },
        {
          prop: 'includeInOrderSummary',
          width: 60,
          align: 'center',
          text: (
            <Translate id="store.config.order_flow.col.include_in_order_summary" />
          ),
          render: ({ includeInOrderSummary }) => (
            <DisplayInOrderSummaryLabel value={includeInOrderSummary} />
          ),
        },
        {
          prop: 'prop',
          text: <Translate id="store.config.extended_property.col.detail" />,
          width: 250,
          render: property =>
            property.type === 'select' ? (
              <Translate
                id="store.config.extended_property.prop_detail.select"
                data={{
                  prop: property.prop || (
                    <Translate id="store.config.extended_property.prop_detail.prop_default" />
                  ),
                  optionCount: property.options.length,
                  selectType: (
                    <Translate
                      id={`store.config.extended_property.prop_detail.${
                        property.multi ? 'multi' : 'single'
                      }_select`}
                    />
                  ),
                }}
              />
            ) : property.type === 'text' ? (
              <Translate
                id="store.config.extended_property.prop_detail.text"
                data={{
                  prop: property.prop || (
                    <Translate id="store.config.extended_property.prop_detail.prop_default" />
                  ),
                  min_len: property.minLength ?? 0,
                  max_len: property.maxLength ?? (
                    <Translate id="store.config.extended_property.prop_detail.unlimited_text_len" />
                  ),
                  multiline: property.multiline ? (
                    <>
                      {', '}
                      <Translate id="store.config.extended_property.prop_detail.multiline_text" />
                    </>
                  ) : null,
                }}
              />
            ) : property.type === 'switch' ? (
              <Translate
                id="store.config.extended_property.prop_detail.switch"
                data={{
                  prop: property.prop || (
                    <Translate id="store.config.extended_property.prop_detail.prop_default" />
                  ),
                  default_on: property.defaultOn ? (
                    <Translate id="store.config.extended_property.prop_detail.default_on" />
                  ) : (
                    '-'
                  ),
                }}
              />
            ) : property.type === 'datetime' ? (
              <Translate
                id="store.config.extended_property.prop_detail.datetime"
                data={{
                  prop: property.prop || (
                    <Translate id="store.config.extended_property.prop_detail.prop_default" />
                  ),
                  date_type: (
                    <Translate
                      id={`store.config.extended_property.prop_detail.date_type.${
                        property.dateOnly ? 'date' : 'datetime'
                      }`}
                    />
                  ),
                  display_format:
                    property.displayFormat ||
                    (property.dateOnly ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'),
                }}
              />
            ) : null,
        },
        {
          prop: 'description',
          width: 100,
          hidden: true,
          text: (
            <Translate id="store.config.extended_property.col.description" />
          ),
          render: ({ description }) => description || '-',
        },
      ];
      if (canEdit) {
        list.push({
          prop: 'actions',
          text: <Translate id="col.actions" />,
          align: 'center',
          width: 150,
          render: (property, _, index, data) => {
            return (
              <>
                <Button
                  size="small"
                  clean
                  iconOnly
                  data-toggle="tooltip"
                  data-container=".settings-editor"
                  title={getString(
                    'store.config.extended_property.tooltip.edit',
                  )}
                  onClick={() => onEdit?.(property)}
                >
                  <i className="la la-edit" />
                </Button>
                <Button
                  size="small"
                  clean
                  iconOnly
                  data-toggle="tooltip"
                  data-container=".settings-editor"
                  title={getString(
                    'store.config.extended_property.tooltip.del',
                  )}
                  onClick={() => onRemove?.(property)}
                >
                  <i className="la la-trash" />
                </Button>
                <Button
                  size="small"
                  clean
                  iconOnly
                  data-toggle="tooltip"
                  data-container=".settings-editor"
                  title={getString(
                    'store.config.extended_property.tooltip.move_up',
                  )}
                  onClick={() => onMove?.(property, 'up')}
                  disabled={index === 0}
                >
                  <i className="la la-arrow-up" />
                </Button>
                <Button
                  size="small"
                  clean
                  iconOnly
                  data-toggle="tooltip"
                  data-container=".settings-editor"
                  title={getString(
                    'store.config.extended_property.tooltip.move_down',
                  )}
                  onClick={() => onMove?.(property, 'down')}
                  disabled={index === data.length - 1}
                >
                  <i className="la la-arrow-down" />
                </Button>
              </>
            );
          },
        });
      }
      return list;
    }, [canEdit, onEdit, onMove, onRemove]);

    return (
      <>
        {properties.length > 0 && (
          <DataTable<ExtendedPropertyDef, string>
            className="settings__extended-properties-list"
            columns={columns}
            idProp="id"
            selModel="none"
            data={properties}
            minHeight={0}
          />
        )}
        {properties.length === 0 && (
          <div className="settings__extended-properties-empty">
            <Translate id="store.config.extended_property.no_properties" />
          </div>
        )}
        <PropertyEditor
          show={activeProperty != null}
          property={activeProperty?.property}
          groupNames={groupNames}
          onSave={onSave}
          onClose={onEditorClose}
        />
      </>
    );
  },
);
