import { showAppLoading } from 'app/duck/actions';
import { kOrderFieldVisibilityOptions } from 'app/settings/constants';
import { OrderFieldInfo, OrderProperty } from 'app/settings/types';
import { Button, TabNav } from 'lib/metronic/components';
import { AsideRight } from 'lib/metronic/layout';
import {
  CarwashType,
  CustomerType,
  OldPartDisposalType,
  OrderFieldVisibility,
  OrderReservationType,
  OrderReturnType,
  OrderTag,
  OrgUserRoleType,
  OrgUserRoleTypeOptions,
} from 'model';
import { ReactNode, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Fill, Slot } from 'react-slot-fill';
import { systemService } from 'services';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  ScopedTranslate,
  getString,
} from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';

const S = memo(({ id }: { id: string }) => {
  return <ScopedTranslate scope="store.config" id={id} />;
});

type Option = { label: string; value: any };

type EnumPropertyType = Extract<
  OrderProperty,
  | 'carwashType'
  | 'returnType'
  | 'oldPartDisposalType'
  | 'reservationType'
  | 'customerType'
>;

export const EnumValueOptionsMap: Record<EnumPropertyType, Option[]> = {
  carwashType: [
    { value: CarwashType.None, label: 'none' },
    {
      value: CarwashType.NoWash,
      label: 'carwash_type.no_wash',
    },
    { value: CarwashType.FreeWash, label: 'carwash_type.free_wash' },
    { value: CarwashType.PaidWash, label: 'carwash_type.paid_wash' },
  ],
  returnType: [
    { value: OrderReturnType.None, label: 'none' },
    { value: OrderReturnType.OnSite, label: 'return_type.on_site' },
    // { value: OrderReturnType.Pick, label: 'carwash_type.pick' },
    { value: OrderReturnType.Deliver, label: 'return_type.deliver' },
  ],
  oldPartDisposalType: [
    { value: OldPartDisposalType.None, label: 'none' },
    { value: OldPartDisposalType.Keep, label: 'old_part_disposal_type.keep' },
    {
      value: OldPartDisposalType.KeepForLook,
      label: 'old_part_disposal_type.keep_for_look',
    },
  ],
  reservationType: [
    { value: OrderReservationType.None, label: 'none' },
    {
      value: OrderReservationType.Reserved,
      label: 'reservation_type.reserved',
    },
    {
      value: OrderReservationType.NonReserved,
      label: 'reservation_type.non_reserved',
    },
  ],
  customerType: [
    { value: CustomerType.Default, label: 'customer_type.default' },
    { value: CustomerType.Official, label: 'customer_type.official' },
  ],
};

export const OrderFieldEditor = memo(
  ({
    field,
    show,
    groupNames,
    orderTags,
    onClose,
    onSave,
  }: {
    field?: OrderFieldInfo;
    show?: boolean;
    groupNames?: string[];
    orderTags?: string[];
    onClose?: () => void;
    onSave?: (field: OrderFieldInfo) => void;
  }) => {
    const isDirty = useRef(false);

    const handleSave = usePersistFn(async (fieldInfo: OrderFieldInfo) => {
      onSave?.(fieldInfo);
      isDirty.current = false;
    });

    const handleCancel = usePersistFn(() => {
      if (
        !isDirty.current ||
        confirm(getString('@string/confirm_entity_editor_modal_msg'))
      ) {
        isDirty.current = false;
        onClose?.();
      }
    });

    const onChange = usePersistFn(() => {
      isDirty.current = true;
    });

    return (
      <AsideRight
        open={show}
        onClose={handleCancel}
        className="settings__extended-properties-editor-aside"
      >
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active>
              <S id="order_flow.editor.title" />
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content>
          {field && (
            <Content
              field={field}
              groupNames={groupNames}
              orderTags={orderTags}
              onSave={handleSave}
              onCancel={handleCancel}
              onChange={onChange}
            />
          )}
        </AsideRight.Content>
        <Slot name="property-editor-actions" />
      </AsideRight>
    );
  },
);

const Content = memo(
  ({
    onSave,
    onCancel,
    onChange,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    groupNames,
    orderTags,
    ...props
  }: {
    field: OrderFieldInfo;
    onCancel?: () => void;
    onSave?: (field: OrderFieldInfo) => void;
    onChange?: () => void;
    groupNames?: string[];
    orderTags?: string[];
  }) => {
    const showErrorOnChange = useRef(false);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<Record<string, ReactNode>>({});
    const [field, setField] = useState(props.field);
    const [predefinedTags, setPredefinedTags] = useState<OrderTag[]>();

    const name = field.property;

    const handleChange = usePersistFn((values: OrderFieldInfo) => {
      setField((x: any) => ({ ...x, ...values }) as any);
      onChange?.();
    });

    const handleSave = usePersistFn(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const errors: Record<string, ReactNode> = {};

      const values: OrderFieldInfo = { ...field };

      if (name === 'mileage') {
        const value =
          typeof field.defaultValue === 'number'
            ? field.defaultValue
            : field.defaultValue?.trim()
              ? parseInt(field.defaultValue?.trim())
              : undefined;
        if (value != null && (isNaN(value) || value < 0)) {
          errors['defaultValue'] = (
            <Translate id="store.config.order_flow.error.mileage" />
          );
        }
      } else if (name === 'vin') {
        if (values.defaultValue) {
          values.defaultValue = values.defaultValue.trim();
        }
        if (values.required === false) {
          values.defaultValue = '00000000000000000';
        } else if (
          values.defaultValue &&
          values.defaultValue !== '00000000000000000'
        ) {
          errors['defaultValue'] = (
            <Translate id="store.config.order_flow.error.vin" />
          );
        }
      }

      const hasErrors = Object.keys(errors).length > 0;

      showErrorOnChange.current = hasErrors;

      setErrors(errors);

      if (hasErrors) {
        return;
      }

      onSave?.(values);

      showErrorOnChange.current = false;
    });

    const handleCancel = usePersistFn(() => {
      showErrorOnChange.current = false;
      onCancel?.();
    });

    useEffect(() => {
      if (name !== 'tags') return;
      systemService
        .getPredefinedOrderTags()
        .then(setPredefinedTags)
        .catch(err => {
          dispatch(showAppLoading({ status: 'error', message: err.message }));
        });
    }, [dispatch, name]);

    const form = useMemo(() => {
      const supportsAutoFill =
        name === 'extendedProperties' ||
        name === 'customerType' ||
        name === 'customerName' ||
        name === 'customerMobile' ||
        name === 'insuranceExpiryDate';

      const builder = new EntityEditorFormBuilder<OrderFieldInfo>();
      builder
        .withHelpTextPlacement('after')
        .custom({
          label: 'store.config.order_flow.label.property',
          render: () => (
            <span style={{ marginLeft: '0.5rem' }}>
              <Translate id={`store.config.order_flow.props.${name}`} />
            </span>
          ),
        })
        .radioList({
          prop: 'required',
          label: 'store.config.order_flow.label.required',
          inline: true,
          disabled:
            name === 'licensePlateNo' ||
            name === 'vin' ||
            name === 'serviceAgent',
          options: [
            {
              value: true,
              label: getString('store.config.order_flow.values.required.yes'),
            },
            {
              value: false,
              label: getString('store.config.order_flow.values.required.no'),
            },
          ],
          convertValue(value) {
            return value ? String(value) === 'true' : undefined;
          },
        })
        .checkbox({
          prop: 'hidden',
          label: 'store.config.order_flow.label.hidden',
          helpText: 'store.config.order_flow.help_text.hidden',
          disabled: name === 'licensePlateNo',
        })
        .checkbox({
          prop: 'autoFill',
          label: 'store.config.order_flow.label.auto_fill',
          helpText: 'store.config.order_flow.help_text.auto_fill',
          disabled: !supportsAutoFill,
          hidden: !supportsAutoFill,
        })
        .text({
          prop: 'group',
          label: 'store.config.order_flow.label.group',
          placeholder: 'store.config.order_flow.placeholder.group',
          helpText: 'store.config.order_flow.help_text.group',
          hidden: name === 'customerSign',
        });

      if (
        name === 'carwashType' ||
        name === 'returnType' ||
        name === 'oldPartDisposalType' ||
        name === 'reservationType' ||
        name === 'customerType'
      ) {
        const options = (EnumValueOptionsMap[name] ?? []).map(x => ({
          value: x.value,
          label: `store.config.order_flow.values.${x.label}`,
        }));
        builder.radioList({
          prop: 'defaultValue',
          label: 'store.config.order_flow.label.default_value',
          color: 'brand',
          solid: true,
          checkedStyle: { solid: true, color: 'brand' },
          options,
          convertValue(value) {
            return value ? Number(value) : undefined;
          },
        });
      } else if (name === 'mileage') {
        builder.text({
          prop: 'defaultValue',
          type: 'number',
          label: 'store.config.order_flow.label.default_value',
          error: errors['defaultValue'],
        });
      } else if (name === 'remark' || name === 'vin') {
        builder.text({
          prop: 'defaultValue',
          label: 'store.config.order_flow.label.default_value',
          error: errors['defaultValue'],
        });
      } else if (name === 'tags') {
        const options =
          orderTags != null && orderTags.length > 0
            ? orderTags.map<Option>(label => ({
                label,
                value: label,
              }))
            : (predefinedTags ?? []).map(x => ({
                label: x.name,
                value: x.name,
              }));
        builder.reactSelect({
          prop: 'defaultValue',
          label: 'store.config.order_flow.label.default_value',
          multi: true,
          values: options,
          valueProp: 'value',
          labelProp: 'label',
          onValue(value, type) {
            if (type === 'get') {
              return ((value as string) || '')
                .split(',')
                .map(x => x.trim())
                .filter(x => x);
            } else {
              return (value as string[]).join(',');
            }
          },
        });
      }

      if (name === 'customerMobile') {
        builder.checkboxList({
          prop: 'dialRoles',
          label: 'store.config.order_flow.label.dial_roles',
          helpText: 'store.config.order_flow.help_text.dial_roles',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        });
      }

      if (name === 'dashboardImage') {
        builder.checkbox({
          prop: 'watermark',
          label: 'store.config.order_flow.label.watermark_required',
          helpText: 'store.config.order_flow.help_text.watermark_required',
        });
      }

      builder.radioList<OrderFieldVisibility>({
        prop: 'includeInOrderSummary',
        label: 'store.config.order_flow.label.include_in_order_summary',
        helpText: 'store.config.order_flow.help_text.include_in_order_summary',
        options: kOrderFieldVisibilityOptions as any,
        color: 'brand',
        solid: true,
        checkedStyle: { solid: true, color: 'brand' },
        isValueChecked: (value, v) =>
          value === v || (value === 'default' && !v),
      });

      return builder.build();
    }, [orderTags, name, errors, predefinedTags]);

    return (
      <div className="settings__property-editor">
        <form className="entity-editor-form m-form">
          <div className="m-portlet__body">
            <div className="m-form__section m-form__section--first">
              {field && (
                <EntityEditorForm
                  entity={field}
                  onChange={handleChange}
                  elements={form.elements}
                  autocomplete={form.autocomplete}
                  useUncontrolled={form.useUncontrolled}
                  helpTextPlacement="before"
                />
              )}
            </div>
          </div>
        </form>
        <Fill name="aside-actions">
          <div
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              bottom: 0,
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'stretch',
              padding: '1rem',
              backgroundColor: '#fff',
            }}
          >
            <Button block color="brand" onClick={handleSave}>
              <Translate id="save_btn_text" />
            </Button>
            <Button
              block
              color="secondary"
              onClick={handleCancel}
              style={{ marginTop: '0.35rem' }}
            >
              <Translate id="cancel_btn_text" />
            </Button>
          </div>
        </Fill>
      </div>
    );
  },
);
